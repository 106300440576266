<template>
  <div>
      <AdminNavBar/>
      <h1 class="text-center purple-title">Dashboard</h1>
      <div class="row mb-4">
          <div class="offset-md-1 col-md-10 mt-4">
              <AdminPartnerTable/>
          </div>

      </div>
      <div class="row mb-4">           
          <div class="offset-md-1 col-md-10 mt-4">
              <AdminRoomPropertiesTable/>
          </div>
      </div>
      <div class="row mb-4">
          <div class="offset-md-1 col-md-10 mt-4">
              <AdminRoomRecommendationsTable/>
          </div>
      </div>
      <div class="rwo mb-4">
           <div class="offset-md-1 col-md-10 mt-4">
              <AdminFailureAlertsTable/>
          </div>
      </div>

  </div>
</template>

<script>
import AdminNavBar from '../../components/Admin/AdminNavBar'
import AdminPartnerTable from '../../components/Admin/AdminPartnerTable'
import AdminRoomPropertiesTable from '../../components/Admin/AdminRoomPropertiesTable'
import AdminRoomRecommendationsTable from '../../components/Admin/AdminRoomRecommendationsTable'
import AdminFailureAlertsTable from '../../components/Admin/AdminFailureAlertsTable'
import {userService} from '../../_services/index'
export default {
    components:{
        AdminNavBar,
        AdminPartnerTable,
        AdminRoomPropertiesTable,
        AdminRoomRecommendationsTable,
        AdminFailureAlertsTable
    },
    beforeCreate(){
        userService.getIfAdmin()
        .then(() => {

        }, () => {
            this.$router.push('/clients')
        })
    }   
}
</script>

<style>

</style>